.sider {
  overflow: auto;
  height: 100vh;
  border-right: 1px solid #e8e8e8;
  position: sticky !important;
  top: 0;
  z-index: 99;
}
.logo {
  padding: 1.05rem 0 0.75rem;
  text-align: center;
}
.menu {
  border: 0 !important;
  padding: 0.5rem;
}
.siderButton {
  border-radius: 0;
}
