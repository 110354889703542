.card-wrapper {
  padding: 10px;
  border: 1px solid #0d0d0d;
  background: #fff;
  border-radius: 4px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  transform: scale(1);
  position: relative;
  box-shadow: 5px 5px 5px grey;
}

.card-wrapper.hovered {
  border: 1px solid #0d0d0d;
  transform: scale(1.1);
  z-index: 10;
  box-shadow: 10px 10px 10px grey;
}
.full-screen-modal .ant-modal-content {
  height: 100vh;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.full-screen-modal .ant-modal-body {
  flex: 1;
  padding: 0;
  height: 100%;
  display: flex;
}
.vertical-controls {
  top: 50%;
  transform: translateY(-50%);
  box-shadow: none !important;
}
