@import url("https://fonts.googleapis.com/css2?family=Onest:wght@100;200;300;400;500;600&display=swap");

:root {
  --secondary-color: #f39237; /* Default secondary color */
  --primary-color: #045B7C; /* Default secondary color */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* OVERRIDE ANT DEFAULTS */
.ant-menu-item-divider {
  border: 0 !important;
  padding: 1rem 0 !important;
}

.tox-statusbar__branding {
  visibility: hidden;
}

.ant-card-meta-title .ant-space {
  background-color: var(--secondary-color);
}

.ant-card-head .ant-card-extra .ant-btn svg, .ant-drawer .ant-drawer-close {
  color: #fff !important;
}

tbody .ant-table-selection-column {
  background: #fafafa !important;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  box-shadow: inset 0 0 4px grey;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #9e9d9d;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888;
  border-radius: 5px;
}

.ant-table-wrapper .ant-table {
  scrollbar-color: initial !important;
}

.ant-tabs-nav-wrap {
  padding: 1em 0;
}

.ant-card-head-title, .ant-card .ant-card-head, .ant-card-head-title .ant-typography,
.ant-card-extra .anticon, .ant-card-extra .ant-typography, .ant-drawer-header {
  color: #fff !important;
  background-color: var(--primary-color);
}

.ant-btn-primary:disabled, .ant-btn-primary.ant-btn-disabled {
  background: #d9d9d9;
}

.ant-avatar img {
  object-fit: none !important;
}
.tabs-nav-wrap {
  margin-top: -2em;
}
.tabs-nav-wrap.hidden .ant-tabs-nav-wrap {
  display: none !important;
}

.tabs-nav-wrap .ant-tabs-nav-wrap {
  background: #fff;
  padding: 5px;
  margin: 0 -2em;
}
.tabs-nav-wrap .ant-tabs-tab {
  margin-right: 5px !important;
}
.tabs-nav-wrap .ant-tabs-tabpane .ant-tabs-nav-wrap {
  margin: 0;
}

.ant-card-extra .ant-progress .ant-progress-inner {
  background-color: #fff;
  opacity: 0.2;
}
.sub-assemblies-tabs .ant-tabs-nav {
  margin-top: 2em !important;
}

.sub-assemblies-tabs .ant-tabs-nav-wrap {
  background: transparent !important;
  padding: 0;
}

.btn-light {
  background: #fff;
  color: #000;
}

.form-values {
  margin-bottom: 15px;
}

.form-values .ant-typography-secondary {
  margin-top: 5px;
  display: inline-block;
}

.link-secondary-color {
  color: var(--secondary-color) !important;
  margin-top: 10px;
  display: inline-block;
}

.tabs-nav-wrap > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active, .tabs-nav-wrap > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active.ant-tabs-tab-with-remove {
  background-color: var(--primary-color) !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}
.tabs-nav-wrap > .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-with-remove {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}
.ant-tabs-tab .ant-typography, .ant-tabs-tab.ant-tabs-tab-with-remove .products-tab .ant-typography,
.ant-tabs-tab .products-tab .anticon.anticon-close svg, .ant-tabs-tab.ant-tabs-tab-with-remove .products-tab .anticon.anticon-close,
.products-tab .products-icon{
  color: var(--primary-color) !important;
  font-weight: 700;
}

  .ant-tabs-tab.ant-tabs-tab-active .ant-typography , .ant-tabs-tab.ant-tabs-tab-active.ant-tabs-tab-with-remove .products-tab .ant-typography,
  .ant-tabs-tab.ant-tabs-tab-active .products-tab .anticon.anticon-close,
  .ant-tabs-tab.ant-tabs-tab-with-remove.ant-tabs-tab-active .anticon.anticon-close,
  .link-secondary-color:hover, .ant-tabs-tab.ant-tabs-tab-active.ant-tabs-tab-with-remove .products-tab .products-icon,
  .supplier-portal-card .card-content h4, .supplier-portal-card .card-content p {
    color: #fff !important;
  }

  .ant-tabs-tab .anticon.anticon-close {
    content: url('./assets/icons/close-circle-outlined.svg') !important; /* Use a custom SVG icon */
  }

  .ant-card-extra .anticon.anticon-close, .ant-tabs-tab.ant-tabs-tab-active .anticon.anticon-close{
    content: url('./assets/icons/close-circle.svg') !important; /* Use a custom SVG icon */
  }

  /* Supplier portal styles */
  .supplier-portal-card {
    position: relative;
    cursor: pointer;
    background-size: contain !important;
    background-repeat: no-repeat !important;
  }

  .supplier-portal-card::before, .modalBanner::before {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 99;
    top: 0;
    left: 0;
    content: "";
    transition: all 1s ease-in;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%),linear-gradient(360deg, rgba(0, 81, 110, 0.4) 0%, rgba(0, 156, 212, 0.4) 100%);
  }

  .supplier-portal-card:hover::before {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%),linear-gradient(360deg, rgba(0, 81, 110, 0.4) 0%, rgba(181, 82, 82, 0.4) 100%);
  }

  .supplier-portal-card .ant-card-body{
    height: 350px;
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    padding: 20px;
  }
  
  .supplier-portal-card .card-content {
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 40px;
    position: relative;
    z-index: 999;
  }

  .supplier-portal-card .card-content h4 {
    text-transform: uppercase;
  }

  .modalBanner {
    padding: 3em 2em;
    margin: -2.3em -2.4em 0;
    background-size: cover;
    position: relative;
    background-position: center;
  }

 .modalBanner::before {
    background: initial;
    z-index: 0;
    background-color: #009CD4;
    opacity: 0.8;
  }

  .modalBanner h2,  .modalBanner p,
  .supplier-portal-drawer .slide-content h4, .supplier-portal-card .slide-content ul {
    color: #fff;
  }

  .modalBanner iframe {
    border: 7px solid #fff;
    border-radius: 8px;
    width: 90%;
    height: 350px;
  }

  .supplier-portal-drawer .backBtn {
    background: transparent;
    border: none;
    cursor: pointer;
    margin-bottom: 30px;
  }

  .supplier-portal-drawer .modal-body {
    margin: 2em 0;
  }

  .supplier-portal-drawer .slick-dots li.slick-active button {
    background-color: #F7863E;
    color: #F7863E;
  }
  .supplier-portal-drawer .instruction-slides .slick-dots li.slick-active button {
    background-color: #00516E;
    color: #00516E;
  }

  .supplier-portal-drawer .slick-dots li button {
    background-color: #919191;
    color: #919191;
  }

  .supplier-portal-drawer .ant-carousel img{
    max-width: 100%;
    margin-bottom: 20px;
  }

  .supplier-portal-drawer .ant-carousel .slick-dots-bottom  {
    bottom: -8%;
  }
  .supplier-portal-drawer .ant-carousel .slick-dots li button {
    border-radius: 50%;
  }

  .supplier-portal-drawer .slide-content {
    background: linear-gradient(90deg, #F7863E 0%, #914F24 100%);
    border-radius: 5px;
    padding: 20px 25px;
    text-align: left;
    color: #fff;
  }
  .supplier-portal-drawer .instruction-slides .slide-content {
    background: linear-gradient(180deg, #00516E 0%, #009CD4 100%);
  }

  /* end of supplier portal styles */

  .secondary-btn {
    background: #1890FF;
    color: #fff;
  }

  .inactive-text {
    color: rgba(0, 0, 0, 0.25) !important;
  }

  .filter-tag {
    color: #fff;
    background: var(--secondary-color) !important;
    border-radius: 20px;
    padding: 5px 15px;
    font-size: 14px;
    display: block;
  }
  .filter-tag svg {
    color: #fff;
    margin-left: 5px;
  }